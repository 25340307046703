// NotFound.js
import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
        <br/><h1>Privacy Policy</h1> <br/>
        <p><b>Last Updated: [13/06/2024]</b> <br/><br/>
            <b>1. Introduction</b> <br/><br/>
            Welcome to Brawl Track. We value your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, and safeguard your information when you use our Brawl Stars tracker service. <br/><br/>
            <b>2. Information We Collect</b> <br/><br/>
            <b>2.1. Personal Data</b> <br/><br/>
            When you register to use our Service, we collect the following personal information: <br/>
            - Username <br/>
            - Email address <br/>
            - Encrypted password <br/><br/>
            <b>2.2. Game Data</b> <br/><br/>
            We use the official Brawl Stars API to retrieve game-related data, including but not limited to: <br/>
            - Player ID <br/>
            - Player statistics <br/>
            - Clan information <br/><br/>
            <b>3. How We Use Your Information</b> <br/><br/>
            We use your information to: <br/>
            - Provide and maintain our Service <br/>
            - Personalize your experience <br/>
            - Improve our Service <br/>
            - Communicate with you <br/>
            - Ensure the security of our Service <br/><br/>
            <b>4. Data Storage and Security</b> <br/><br/>
            <b>4.1. Data Storage</b> <br/><br/>
            Your data is stored securely in our database. We implement industry-standard encryption to protect your passwords. <br/><br/>
            <b>4.2. Security Measures</b> <br/><br/>
            We employ various security measures to protect your information, including: <br/>
            - Encryption of sensitive data <br/>
            - Secure servers and networks <br/>
            - Regular security audits <br/><br/>
            <b>5. Data Sharing and Disclosure</b> <br/><br/>
            We do not sell, trade, or otherwise transfer your personal data to outside parties except as described in this policy. We may share your information with: <br/>
            - Service providers who assist us in operating our Service <br/>
            - Law enforcement or other governmental authorities if required by law <br/><br/>
            <b>6. Your Rights</b> <br/><br/>
            You have the following rights regarding your personal data: <br/>
            - Access: You can request access to the personal data we hold about you. <br/>
            - Correction: You can request that we correct any inaccuracies in your data. <br/>
            - Deletion: You can request that we delete your personal data, subject to certain conditions. <br/>
            - Objection: You can object to our processing of your personal data under certain circumstances. <br/><br/>
            <b>7. Third-Party Links</b> <br/><br/>
            Our Service may contain links to third-party websites. We are not responsible for the privacy practices or the content of these sites. <br/><br/>
            <b>8. Changes to This Privacy Policy</b> <br/><br/>
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our website. You are advised to review this privacy policy periodically for any changes. <br/><br/>
            <b>9. Contact Us</b> <br/><br/>
            If you have any questions or concerns about this privacy policy or our data practices, please contact us at: <br/>
            - Email: brawltrack.oficial@gmail.com <br/>
            - By using our Service, you consent to our privacy policy. <br/>
        </p> <br/>
    </div>
  );
}

export default PrivacyPolicy;
