// NotFound.js
import React from 'react';

function NotFound() {
  return (
    <div className='containerNotFound'>
      <h1>404 - Page not found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFound;
