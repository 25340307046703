
import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import loadingGif from '../assets/images/loading.gif';

function VS() {
    const [playerTag1, setPlayerTag1] = useState('');
    const [playerTag2, setPlayerTag2] = useState('');
    const [playerData1, setPlayerData1] = useState(null);
    const [playerData2, setPlayerData2] = useState(null);
    const [brawlersData, setBrawlersData] = useState(null);
    const [mensajePlayer1, setMensajePlayer1] = useState('');
    const [mensajePlayer2, setMensajePlayer2] = useState('');
    const [mensajeRealizado, setMensajeRealizado] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [allBrawlers, setAllBrawlers] = useState(true);
    const detailsRef = useRef(null);
    const puntuacion = {
        "SHELLY": 20,
        "NITA": 35,
        "COLT": 60,
        "BULL": 40,
        "BROCK": 50,
        "EL PRIMO": 1000,
        "BARLEY": 66,
        "POCO": 15,
        "ROSA": 25,
        "JESSIE": 45,
        "DYNAMIKE": 70,
        "TICK": 30,
        "8-BIT": 61,
        "RICO": 57,
        "DARRYL": 51,
        "PENNY": 36,
        "CARL": 33,
        "JACKY": 5,
        "GUS": 53,
        "BO": 49,
        "EMZ": 48,
        "STU": 69,
        "PIPER": 63,
        "PAM": 31,
        "FRANK": 62,
        "BIBI": 46,
        "BEA": 65,
        "NANI": 90,
        "EDGAR": -1000,
        "GRIFF": 44,
        "GROM": 52,
        "BONNIE": 23,
        "GALE": 39,
        "COLETTE": 64,
        "BELLE": 43,
        "ASH": 24,
        "LOLA": 55,
        "SAM": 72,
        "MANDY": 67,
        "MAISIE": 75,
        "HANK": 68,
        "PEARL": 42,
        "LARRY & LAWRIE": 41,
        "ANGELO": 56,
        "MORTIS": 100,
        "TARA": 59,
        "GENE": 78,
        "MAX": 76,
        "MR. P": 58,
        "SPROUT": 73,
        "BYRON": 74,
        "SQUEAK": 32,
        "LOU": 37,
        "RUFFS": 29,
        "BUZZ": 11,
        "FANG": 4,
        "EVE": 34,
        "JANET": 26,
        "OTIS": 21,
        "BUSTER": 14,
        "GRAY": 85,
        "R-T": 18,
        "WILLOW": 47,
        "DOUG": 17,
        "CHUCK": 28,
        "CHARLIE": 12,
        "MICO": 16,
        "MELODIE": 22,
        "LILY": 77,
        "SPIKE": 27,
        "CROW": 38,
        "LEON": 19,
        "SANDY": 71,
        "AMBER": 6,
        "MEG": 54,
        "SURGE": 7,
        "CHESTER": 8,
        "CORDELIUS": 79,
        "KIT": 9,
        "DRACO": 10
    };

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    const isDataFetched = playerData1 !== null && playerData1.name !== undefined && playerData2 !== null && playerData2.name !== undefined;

    useEffect(() => {
        // Esta función se ejecuta cuando el componente se monta
        // Aquí podemos obtener la lista de brawlers
        axiosInstance.get('/brawlers')
            .then(response => {
                setBrawlersData(response.data);
                setAllBrawlers(false);
                
            })
            .catch(error => {
                console.error('Error fetching brawlers:', error);
                setAllBrawlers(false);
            });
    }, []);

    useEffect(() => {
        if (!allBrawlers && brawlersData.items === undefined) {
            setBrawlersData({ items: [] });
            setBrawlersData(prevState => ({
                ...prevState,
                items: Array(83).fill(null)
            }));
            setAllBrawlers(true);
        }
    }, [brawlersData]);

    const getPlayerDetails = () => {
        if (!playerTag1 || !playerTag2) {
            setError('Player tag is required');
            return;
        }
        setIsLoading(true);
        setPlayerData1(null);
        setPlayerData2(null);
        setMensajeRealizado(false);
        axiosInstance
        .get(`/${playerTag1}`)
        .then((response) => {
            setPlayerData1(response.data);
            setError('');
        })
        .catch((err) => {
            setError(err.response.data.message || 'Player Not Found');
            setPlayerData1(null);
        });
        axiosInstance
        .get(`/${playerTag2}`)
        .then((response) => {
            setPlayerData2(response.data);
            setError('');
        })
        .catch((err) => {
            setError(err.response.data.message || 'Player Not Found');
            setPlayerData2(null);
        });
    }

    if(isDataFetched && !mensajeRealizado){
        setTimeout(() => {
            detailsRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
    }

    useEffect(() => {
        if (playerData1 === "Player Not Found" || playerData2 === "Player Not Found") {
            setIsLoading(false);
        }
    }, [playerData1, playerData2]);

    useEffect(() => {
        if (isDataFetched) {
            setIsLoading(false); // Finalizar carga cuando los datos están completos
        }
    }, [isDataFetched]);

    // Trophies
    const getTrophyClass = (trophies1, trophies2) => {
        if (trophies1 > trophies2) {
            return 'winner';
        } else if (trophies1 < trophies2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const trophyClass1 = isDataFetched ? getTrophyClass(playerData1.trophies, playerData2.trophies) : '';
    const trophyClass2 = isDataFetched ? getTrophyClass(playerData2.trophies, playerData1.trophies) : '';

    // Hight trophies
    const getHightTrophyClass = (hightTrophies1, hightTrophies2) => {
        if (hightTrophies1 > hightTrophies2) {
            return 'winner';
        } else if (hightTrophies1 < hightTrophies2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const hightTrophyClass1 = isDataFetched ? getHightTrophyClass(playerData1.highestTrophies, playerData2.highestTrophies) : '';
    const hightTrophyClass2 = isDataFetched ? getHightTrophyClass(playerData2.highestTrophies, playerData1.highestTrophies) : '';

    // Level
    const getLevelClass = (level1, level2) => {
        if (level1 > level2) {
            return 'winner';
        } else if (level1 < level2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const levelClass1 = isDataFetched ? getLevelClass(playerData1.expLevel, playerData2.expLevel) : '';
    const levelClass2 = isDataFetched ? getLevelClass(playerData2.expLevel, playerData1.expLevel) : '';

    // Unlocked Brawlers
    const getUnlockedBrawlersClass = (unlockedBrawlers1, unlockedBrawlers2) => {
        if (unlockedBrawlers1 > unlockedBrawlers2) {
            return 'winner';
        } else if (unlockedBrawlers1 < unlockedBrawlers2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const unlockedBrawlersClass1 = isDataFetched ? getUnlockedBrawlersClass(playerData1.brawlers.length, playerData2.brawlers.length) : '';
    const unlockedBrawlersClass2 = isDataFetched ? getUnlockedBrawlersClass(playerData2.brawlers.length, playerData1.brawlers.length) : '';

    // Solo Wins
    const getSoloWinsClass = (soloWins1, soloWins2) => {
        if (soloWins1 > soloWins2) {
            return 'winner';
        } else if (soloWins1 < soloWins2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const soloWinsClass1 = isDataFetched ? getSoloWinsClass(playerData1.soloVictories, playerData2.soloVictories) : '';
    const soloWinsClass2 = isDataFetched ? getSoloWinsClass(playerData2.soloVictories, playerData1.soloVictories) : '';

    // Duo Wins
    const getDuoWinsClass = (duoWins1, duoWins2) => {
        if (duoWins1 > duoWins2) {
            return 'winner';
        } else if (duoWins1 < duoWins2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const duoWinsClass1 = isDataFetched ? getDuoWinsClass(playerData1.duoVictories, playerData2.duoVictories) : '';
    const duoWinsClass2 = isDataFetched ? getDuoWinsClass(playerData2.duoVictories, playerData1.duoVictories) : '';

    // 3vs3 Wins
    const get3vs3WinsClass = (a3vs3Wins1, a3vs3Wins2) => {
        if (a3vs3Wins1 > a3vs3Wins2) {
            return 'winner';
        } else if (a3vs3Wins1 < a3vs3Wins2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const a3vs3WinsClass1 = isDataFetched ? get3vs3WinsClass(playerData1["3vs3Victories"], playerData2["3vs3Victories"]) : '';
    const a3vs3WinsClass2 = isDataFetched ? get3vs3WinsClass(playerData2["3vs3Victories"], playerData1["3vs3Victories"]) : '';

    // Robo Rumble
    const getRoboRumbleClass = (roboRumble1, roboRumble2) => {
        if (roboRumble1 > roboRumble2) {
            return 'winner';
        } else if (roboRumble1 < roboRumble2) {
            return 'loser';
        } else {
            return 'tie';
        }
    };
    
    const roboRumbleClass1 = isDataFetched ? getRoboRumbleClass(playerData1.bestRoboRumbleTime, playerData2.bestRoboRumbleTime) : '';
    const roboRumbleClass2 = isDataFetched ? getRoboRumbleClass(playerData2.bestRoboRumbleTime, playerData1.bestRoboRumbleTime) : '';

    const handleChange1 = (e) => {
        const value = e.target.value.toUpperCase(); // Convert to uppercase
        const alphanumericRegex = /^[A-Z0-9]*$/; // Only allow uppercase letters and numbers

        if (alphanumericRegex.test(value)) {
            setPlayerTag1(value);
        }
    };

    const handleChange2 = (e) => {
        const value = e.target.value.toUpperCase(); // Convert to uppercase
        const alphanumericRegex = /^[A-Z0-9]*$/; // Only allow uppercase letters and numbers

        if (alphanumericRegex.test(value)) {
            setPlayerTag2(value);
        }
    };

    // SUMAR PUNTUACIONES TOP 3 BRAWLERS
    const calculateScore = (brawlers) => {
        return brawlers
            .sort((a, b) => b.trophies - a.trophies) // Ordenar brawlers por copas descendente
            .slice(0, 3) // Tomar los primeros 3 brawlers
            .reduce((acc, brawler) => acc + (puntuacion[brawler.name] || 0), 0); // Sumar las puntuaciones
    };

    const puntuacionPlayer1 = isDataFetched ? calculateScore(playerData1.brawlers) : 0;
    const puntuacionPlayer2 = isDataFetched ? calculateScore(playerData2.brawlers) : 0;

    // MENSAJE PARA CADA PLAYER SEGUN TOP 3
    if(puntuacionPlayer1 != 0 && puntuacionPlayer2 != 0 && !mensajeRealizado){
        if(puntuacionPlayer1 > puntuacionPlayer2){
            setMensajePlayer1("You're the real goat! Enjoy your victory, champ!");
            setMensajePlayer2("Ouch, that was embarrassing! Better crawl back and practice, loser!");
            setMensajeRealizado(true);
        }else if(puntuacionPlayer1 < puntuacionPlayer2){
            setMensajePlayer2("You're the real goat! Enjoy your victory, champ!");
            setMensajePlayer1("Ouch, that was embarrassing! Better crawl back and practice, loser!");
            setMensajeRealizado(true);
        }else{
            setMensajePlayer1("Looks like it's a draw! Both of you need to step it up if you want to be the best!");
            setMensajePlayer2("Looks like it's a draw! Both of you need to step it up if you want to be the best!");
            setMensajeRealizado(true);
        }
    }

    return (
        <div className="container">
            <div className="formVS">
                <div>Type your tag and your buddy's to see who's the real goat. Let the trash talk begin!</div>
                <div className='searchBarVS'>
                    <input
                        type="text"
                        className="form__inputVS"
                        placeholder="#XXXXXXXX"
                        value={playerTag1}
                        onChange={handleChange1}
                    />
                    
                    <input
                        type="text"
                        className="form__inputVS"
                        placeholder="#XXXXXXXX"
                        value={playerTag2}
                        onChange={handleChange2}
                    />

                </div>
                <div className='searchbuttonvs'>
                    <button className="form__submitvs" onClick={getPlayerDetails} disabled={isLoading}>
                            {!isLoading && <div className='divVS' alt="Search Icon">VS</div>}
                            {isLoading && <div className="loading"><img src={loadingGif} alt="Loading..." /></div>}
                    </button>
                </div>
            </div>

            <div ref={detailsRef}></div>

            
            {isDataFetched && (
                <div className="detailsVS">
                    <div className='infoTableVS blueVS'>
                        <div className="details__item">
                            <div className="details__value"><img className='iconImgVS' src={`https://cdn.brawlstats.com/player-thumbnails/${playerData1.icon.id}.png`} alt="Player Icon"/></div>
                            <div>
                                <Link to={`/${playerData1.tag.replace(/^#/, '')}`}>
                                    <div className="details__nameVS">{playerData1.name}</div>
                                </Link>
                                {
                                    playerData1.club.tag ? (
                                        <Link to={`/club/${playerData1.club.tag.replace(/^#/, '')}`}>
                                            <div className="details__clubVS"><img className='clubImg' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABzBAMAAAB3KAFHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURUdwTAAAAAAAAAAAAAAAAGRkZDAwMAAAAMzMzE5OTm9vb6ysrMPDw4SEhMcnUpgAAAAFdFJOUwDAOvKEUYVM9wAAAslJREFUWMPt2EFLG0EUAOCn9QdIW/AaacFrK0KuAQ/5BRG60WNGcmu6xcF/MDTp0ZT+gEBpehUWo8eCaHoMBKL/pftmZ3bfzO5OMtDUpuQdZMD9HOe9N8PuAMDrF3zxCF9WII4t7hcfEe14Ir7tP5Gc6pU3CitQ5/yrT3zhvBYv6bzhE+9wUVV/9Aa4P3r/BOhkHMc9jpo4usPRA44ucXSNo0keiTi6EuHoE46mOJIowlEJuneiQQ41/9pMwfc4BgWjhjl6ojr906ipEuqKQKbUE32TtV+jlUOB2i1eaL2f/uczYjweLOVgWW/CVT0jHh+Xc0Ykh896E67eGTEaLeeM+CFfcf9Al3fnGPWIicQvpzm5zaEgRj0nitSLLU35gxCfnSh+Ce5NLBRE4qcTHQtxlX9bjibuNSXG6ohA/ixP/I1R3LM2+dWoYL4ZbQimEGtl54Do5uYaCvKHmELnjF3QCg5sk3w5yOiztp4pU1P9wZE1wi0pRp8xliGthvghcmc1QtphaCjSKi6yUbBjoYqqjYGYeupa0HXjF1JPLelIPibXtKORSnwQ9ayeu9LJZmqmbbwkYKa6MXvOMqeI9ritEqr/xyBneAU2eKZaJNPdwfCyQYuamg7AZrVQTUWX7hWWLojzfQDYxYFKRtYakaAHQJ+YjrxkOSxQ8ls0zT01YQ0gvWWx1AxnmuQN3rDI2DRUQ7eGboQjkgT+AXTUOUmGVjPLJBcstRTtcara5jFM58Ea6XjGS5VlwtRkd0c5ZZnkegpI/kgyWgWNYOQOY4MXK2YkQXYQiV1bXeQKlDaDW9mGW0Y1k6FsE9YgF3Vb2UkoMKqZaGuY5jnAomqO0fvRUKnZh7LYslVqDqA8rCKfFhfVDrNcZUV1qUUNufg8Ixebc+PQvgytzTekXO4CudRixlSLGlrkAwBv5WN0uToV8Fa+JlbVt6XmN2Ev87IHEC6HAAAAAElFTkSuQmCC"/>{playerData1.club.name}</div>
                                        </Link>
                                    ) : null
                                }
                            </div>
                            <div className="details__tag">{playerData1.tag}</div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfoVS ${trophyClass1}`}>
                                <img src="https://cdn-old.brawlify.com/icon/trophy.png"></img>
                                <div>Trophies</div>
                                <div>{playerData1.trophies}</div>
                            </div>
                            <div className={`columnaInfoVS ${hightTrophyClass1}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Ranking.png"></img>
                                <div>High Trophies</div>
                                <div>{playerData1.highestTrophies}</div>
                            </div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfoVS ${levelClass1}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Info.png"></img>
                                <div>Level</div>
                                <div>{playerData1.expLevel}</div>
                            </div>
                            <div className={`columnaInfoVS ${unlockedBrawlersClass1}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Unlocked.png"></img>
                                <div>Unlocked Brawlers</div>
                                <div>{playerData1.brawlers.length}/{brawlersData.items.length}</div>
                            </div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfoVS ${soloWinsClass1}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Showdown.png"></img>
                                <div>Solo Wins</div>
                                <div>{playerData1.soloVictories}</div>
                            </div>
                            <div className={`columnaInfoVS ${duoWinsClass1}`}>
                                <img src="https://cdn-old.brawlify.com/gamemode/Duo-Showdown.png"></img>
                                <div>Duo Wins</div>
                                <div>{playerData1.duoVictories}</div>
                            </div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfoVS ${a3vs3WinsClass1}`}>
                                <img src="https://cdn-old.brawlify.com/icon/3v3.png"></img>
                                <div>3 vs 3 Wins</div>
                                <div>{playerData1["3vs3Victories"]}</div>
                            </div>
                            <div className={`columnaInfoVS ${roboRumbleClass1}`}>
                                <img src="https://cdn-old.brawlify.com/gamemode/Robo-Rumble.png"></img>
                                <div>Robo Rumble</div>
                                <div>Level {playerData1.bestRoboRumbleTime}</div>
                            </div>
                        </div>
                    </div>
                    <div className='infoTableVS redVS'>
                        <div className="details__item">
                            <div className="details__value"><img className='iconImgVS' src={`https://cdn.brawlstats.com/player-thumbnails/${playerData2.icon.id}.png`} alt="Player Icon"/></div>
                            <div>
                                <Link to={`/${playerData2.tag.replace(/^#/, '')}`}>
                                    <div className="details__nameVS">{playerData2.name}</div>
                                </Link>
                                {
                                    playerData2.club.tag ? (
                                        <Link to={`/club/${playerData2.club.tag.replace(/^#/, '')}`}>
                                            <div className="details__clubVS"><img className='clubImg' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABzBAMAAAB3KAFHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURUdwTAAAAAAAAAAAAAAAAGRkZDAwMAAAAMzMzE5OTm9vb6ysrMPDw4SEhMcnUpgAAAAFdFJOUwDAOvKEUYVM9wAAAslJREFUWMPt2EFLG0EUAOCn9QdIW/AaacFrK0KuAQ/5BRG60WNGcmu6xcF/MDTp0ZT+gEBpehUWo8eCaHoMBKL/pftmZ3bfzO5OMtDUpuQdZMD9HOe9N8PuAMDrF3zxCF9WII4t7hcfEe14Ir7tP5Gc6pU3CitQ5/yrT3zhvBYv6bzhE+9wUVV/9Aa4P3r/BOhkHMc9jpo4usPRA44ucXSNo0keiTi6EuHoE46mOJIowlEJuneiQQ41/9pMwfc4BgWjhjl6ojr906ipEuqKQKbUE32TtV+jlUOB2i1eaL2f/uczYjweLOVgWW/CVT0jHh+Xc0Ykh896E67eGTEaLeeM+CFfcf9Al3fnGPWIicQvpzm5zaEgRj0nitSLLU35gxCfnSh+Ce5NLBRE4qcTHQtxlX9bjibuNSXG6ohA/ixP/I1R3LM2+dWoYL4ZbQimEGtl54Do5uYaCvKHmELnjF3QCg5sk3w5yOiztp4pU1P9wZE1wi0pRp8xliGthvghcmc1QtphaCjSKi6yUbBjoYqqjYGYeupa0HXjF1JPLelIPibXtKORSnwQ9ayeu9LJZmqmbbwkYKa6MXvOMqeI9ritEqr/xyBneAU2eKZaJNPdwfCyQYuamg7AZrVQTUWX7hWWLojzfQDYxYFKRtYakaAHQJ+YjrxkOSxQ8ls0zT01YQ0gvWWx1AxnmuQN3rDI2DRUQ7eGboQjkgT+AXTUOUmGVjPLJBcstRTtcara5jFM58Ea6XjGS5VlwtRkd0c5ZZnkegpI/kgyWgWNYOQOY4MXK2YkQXYQiV1bXeQKlDaDW9mGW0Y1k6FsE9YgF3Vb2UkoMKqZaGuY5jnAomqO0fvRUKnZh7LYslVqDqA8rCKfFhfVDrNcZUV1qUUNufg8Ixebc+PQvgytzTekXO4CudRixlSLGlrkAwBv5WN0uToV8Fa+JlbVt6XmN2Ev87IHEC6HAAAAAElFTkSuQmCC"/>{playerData2.club.name}</div>
                                        </Link>
                                    ) : null
                                }
                            </div>
                            <div className="details__tag">{playerData2.tag}</div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfoVS ${trophyClass2}`}>
                                <img src="https://cdn-old.brawlify.com/icon/trophy.png"></img>
                                <div>Trophies</div>
                                <div>{playerData2.trophies}</div>
                            </div>
                            <div className={`columnaInfoVS ${hightTrophyClass2}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Ranking.png"></img>
                                <div>High Trophies</div>
                                <div>{playerData2.highestTrophies}</div>
                            </div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfoVS ${levelClass2}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Info.png"></img>
                                <div>Level</div>
                                <div>{playerData2.expLevel}</div>
                            </div>
                            <div className={`columnaInfoVS ${unlockedBrawlersClass2}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Unlocked.png"></img>
                                <div>Unlocked Brawlers</div>
                                <div>{playerData2.brawlers.length}/{brawlersData.items.length}</div>
                            </div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfoVS ${soloWinsClass2}`}>
                                <img src="https://cdn-old.brawlify.com/icon/Showdown.png"></img>
                                <div>Solo Wins</div>
                                <div>{playerData2.soloVictories}</div>
                            </div>
                            <div className={`columnaInfoVS ${duoWinsClass2}`}>
                                <img src="https://cdn-old.brawlify.com/gamemode/Duo-Showdown.png"></img>
                                <div>Duo Wins</div>
                                <div>{playerData2.duoVictories}</div>
                            </div>
                        </div>
                        <div className='filaInfoVS'>
                            <div className={`columnaInfo ${a3vs3WinsClass2}`}>
                                <img src="https://cdn-old.brawlify.com/icon/3v3.png"></img>
                                <div>3 vs 3 Wins</div>
                                <div>{playerData2["3vs3Victories"]}</div>
                            </div>
                            <div className={`columnaInfoVS ${roboRumbleClass2}`}>
                                <img src="https://cdn-old.brawlify.com/gamemode/Robo-Rumble.png"></img>
                                <div>Robo Rumble</div>
                                <div>Level {playerData2.bestRoboRumbleTime}</div>
                            </div>
                        </div>
                    </div>
                    <div>   
                        <h1 className='top3VS'>Top 3 Brawlers </h1>                
                        <div className='blueVS vsBox'>
                            <div className='imgTop3Brawl'>
                                {playerData1.brawlers
                                    .sort((a, b) => b.trophies - a.trophies) // Ordenar brawlers por copas descendente
                                    .slice(0, 3) // Tomar los primeros 3 brawlers
                                    .map(brawler => (
                                        <img src={`https://cdn.brawlstats.com/character-arts/${brawler.id}.png`} alt={brawler.name} />
                                    ))}
                            
                            </div>
                            <div className='textVS'>{mensajePlayer1}</div>
                        </div>
                    </div>
                    <div>   
                        <h1 className='h1VS top3VS'>.</h1>         
                        <div className='redVS vsBox'>
                            <div className='imgTop3Brawl'>
                                {playerData2.brawlers
                                    .sort((a, b) => b.trophies - a.trophies) // Ordenar brawlers por copas descendente
                                    .slice(0, 3) // Tomar los primeros 3 brawlers
                                    .map(brawler => (
                                        <img src={`https://cdn.brawlstats.com/character-arts/${brawler.id}.png`} alt={brawler.name} />
                                    ))}
                            </div>
                            <div className='textVS'>{mensajePlayer2}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VS;
