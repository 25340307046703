import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Search from './components/Search';
import Club from './components/Club';
import Top from './components/Top';
import Register from './components/Register';
import Login from './components/Login';
import Profile from './components/Profile';
import VS from './components/VS';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import CookiesPolicy from './components/CookiesPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';

// src/index.js o src/App.js
import './assets/fonts/fonts.css';

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(
    localStorage.getItem('userLoggedIn') !== null && localStorage.getItem('userLoggedIn') !== ''
  );

  const handleUserLogin = () => {
    setUserLoggedIn(true);
  };

  const handleUserLogout = () => {
    localStorage.clear();
    setUserLoggedIn(false);
  };

  return (
    <div>
       <Routes>
          <Route path='/' element={userLoggedIn ? <Layout userLogged='true'/> : <Layout userLogged='false'/>}>
            <Route path='/:playerTagBySearch?' element={<Search />} />
            <Route path='club/:clubTagBySearch?' element={<Club />} />
            <Route path='top' element={<Top />} />
            <Route path='vs' element={<VS />} />
            <Route path='login' element={<Login onUserLogin={handleUserLogin} />} />
            <Route path='profile' element={userLoggedIn ? <Profile onUserLogout={handleUserLogout} /> : <Navigate to="/" />} />
            <Route path='register' element={<Register />} />
            <Route path='cookiesPolicy' element={<CookiesPolicy />} />
            <Route path='privacyPolicy' element={<PrivacyPolicy />} />
            <Route path='*' element={<NotFound />} />
          </Route>
       </Routes>
       <Footer />
    </div>
  );
}

export default App;
