// NotFound.js
import React from 'react';

function CookiesPolicy() {
  return (
    <div>
        <br/><h1>Cookies Policy</h1> <br/>
        <p><b>Last Updated: [13/06/2024]</b> <br/><br/>
            <b>1. Introduction</b> <br/><br/>
            Brawl Track uses cookies and similar tracking technologies to enhance your experience on our Brawl Stars tracker service. This Cookies Policy explains what cookies are, how we use them, and your choices regarding their use. <br/><br/>
            <b>2. What Are Cookies?</b> <br/><br/>
            Cookies are small text files that are placed on your device (computer, smartphone, tablet) when you visit a website. They are widely used to make websites work efficiently, as well as to provide information to the site owners. <br/><br/>
            <b>3. How We Use Cookies</b> <br/><br/>
            We use cookies for various purposes, including: <br/>
            - Essential Cookies: These cookies are necessary for the operation of our Service. They enable you to navigate our site and use its features. <br/>
            - Performance Cookies: These cookies collect information about how you use our Service, such as which pages you visit most often. This information helps us improve the functionality and performance of our site. <br/>
            - Functionality Cookies: These cookies allow our Service to remember choices you make (such as your username) and provide enhanced, more personalized features. <br/>
            - Analytics Cookies: We use analytics cookies to understand how our users interact with our Service. This helps us improve our Service by making it more user-friendly. <br/><br/>
            <b>4. Third-Party Cookies</b> <br/><br/>
            In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service and deliver advertisements on and through the Service. <br/><br/>
            <b>5. Your Choices Regarding Cookies</b> <br/><br/>
            <b>5.1. Managing Cookies</b> <br/><br/>
            You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by adjusting your browser settings. Most web browsers allow you to control cookies through their settings. However, if you choose to disable cookies, some features of our Service may not function properly. <br/><br/>
            <b>5.2. Opt-Out Links</b> <br/><br/>
            You can opt out of third-party cookies from Google Analytics by visiting the Google Analytics opt-out page: Google Analytics Opt-out. <br/><br/>
            <b>6. Changes to This Cookies Policy</b> <br/><br/>
            We may update this Cookies Policy from time to time. We will notify you of any changes by posting the new Cookies Policy on our website. You are advised to review this Cookies Policy periodically for any changes. <br/><br/>
            <b>7. Contact Us</b> <br/><br/>
            If you have any questions about this Cookies Policy, please contact us at: <br/>
            - Email: brawltrack.oficial@gmail.com <br/>
            - By using our Service, you consent to our use of cookies in accordance with this Cookies Policy. <br/>
        </p> <br/>
    </div>
  );
}

export default CookiesPolicy;
