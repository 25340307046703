// Footer.js
import React, { useState, useEffect} from 'react';
import web from '../assets/images/web.png';
import tiktok from '../assets/images/tiktok.png';
import instagram from '../assets/images/instagram.png';
import email from '../assets/images/email.png';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <footer>
      <div className='containerFooter'>
        <div className='infoFooter'>
          <div className='tituloFooter'>Developers</div>
          <div className='nameDev'>Hugo Varela</div>
          <div className='socialFooter'>
            <Link to="https://devache.es/">
              <img src={web} alt="devache.es" />
            </Link>
            <Link to="https://www.instagram.com/devache__/">
              <img src={instagram} alt="devache instagram" />
            </Link>
            <Link to="https://www.tiktok.com/@devache_">
              <img src={tiktok} alt="devache tiktok" />
            </Link>
          </div>
          <div className='nameDev'>Lucas Moreno</div>
          <div className='socialFooter'>
            <Link to="mailto:lucas.moreno.medina@gmail.com">
              <img src={email} alt="devache email" />
            </Link>
            <Link to="https://www.instagram.com/lucasmoreeeno/">
              <img src={instagram} alt="devache instagram" />
            </Link>
          </div>
        </div>
        <div className='infoFooter'>
          <div className='tituloFooter'>Brawl Track</div>
          <div className='socialFooter'>
            <Link to="mailto:brawltrack.oficial@gmail.com">
              <img src={email} alt="devache email" />
            </Link>
            <Link to="https://www.instagram.com/brawltrack/">
              <img src={instagram} alt="devache instagram" />
            </Link>
            <Link to="https://www.tiktok.com/@brawltrack">
              <img src={tiktok} alt="devache tiktok" />
            </Link>
          </div>
        </div>
        <div className='infoFooter'>
          <div className='tituloFooter'>Policies</div>
          <Link to="privacyPolicy" className='linkFooter'><div className='policies'>Privacy Policy</div></Link>
          <Link to="cookiesPolicy" className='linkFooter'><div className='policies'>Cookies Policy</div></Link>
          <Link to="https://supercell.com/en/fan-content-policy/" className='linkFooter'><div className='policies'>Supercell Fan Content Policy</div></Link>
        </div>
        <div>
          <div className='logoFooter'></div>
        </div>
        
      </div>
    </footer>
  );
}

export default Footer;
